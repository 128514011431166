import React, { useContext } from 'react';
import Link from 'next/link';
import { ChevronRightIcon, XIcon } from '@heroicons/react/outline';
import { AppContext } from '@/contexts/app.context';
import { useSession, signOut } from 'next-auth/client';
import { useQuery } from 'react-query';
import Api from '@/services/Api';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { Language } from '@types';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const MobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const { push, locale, pathname, query } = useRouter();
  const [session] = useSession();
  const { isMenuOpen, setIsMenuOpen } = useContext(AppContext);
  const { data: genres } = useQuery('header_genres', () =>
    Api.get('v2/site/header')
  );

  const closeMenu = React.useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  const handleLanguageClick = React.useCallback(
    (locale) => {
      push({ pathname, query }, undefined, {
        locale,
      });
    },
    [pathname, query, push]
  );

  return (
    <aside
      className={`flex flex-col lg:hidden transform top-0 left-0 w-full bg-black fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50
        ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      <button
        type="button"
        className="relative w-full py-6 p-2 flex justify-end items-center bg-black focus:outline-none"
        onClick={closeMenu}
      >
        <div className="px-4">
          <XIcon className="text-white w-8 h-6" />
        </div>
      </button>
      <div className="flex flex-col justify-between flex-grow py-6">
        <ul className="px-8 font-semibold">
          <li className="py-5">
            <Link href="/">{t('browse')}</Link>
          </li>
          {/* <li className="py-5">
            <Link href="/channels">{t('channels')}</Link>
          </li> */}
          <li className="py-5">
            <Link href="/movies">{t('movies')}</Link>
          </li>
          <li className="py-5">
            <Link href="/tv_show">{t('tvShow')}</Link>
          </li>
          {genres?.length > 0 && (
            <li className="py-5">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className="flex justify-between items-center">
                      <div>{t('collections')}</div>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <ChevronRightIcon
                            className={`w-6 transition-transform transform ${
                              expanded ? 'rotate-90' : 'rotate-0'
                            }`}
                          />
                        )}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="font-normal pl-10 pt-6">
                    <ul className="space-y-4">
                      {genres.map((genre) => (
                        <li key={genre._id}>
                          <Link href={`/genres/${genre.slug}`}>
                            {locale === Language.ES
                              ? genre.name_es
                              : genre.name_en}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </li>
          )}
          <li className="py-5">
            <Accordion allowZeroExpanded allowMultipleExpanded>
              {session && (
                <AccordionItem className="pb-10">
                  <AccordionItemHeading>
                    <AccordionItemButton className="flex justify-between items-center">
                      <div>{t('myAccount')}</div>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <ChevronRightIcon
                            className={`w-6 transition-transform transform ${
                              expanded ? 'rotate-90' : 'rotate-0'
                            }`}
                          />
                        )}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="font-normal pl-10 pt-6">
                    <ul className="space-y-4">
                      <li>
                        <Link href="/user/list">{t('list')}</Link>
                      </li>
                      <li>
                        <Link href="/account">{t('profile:myData')}</Link>
                      </li>
                      <li>
                        <Link href="/password">{t('changePassword')}</Link>
                      </li>
                      <li>
                        <Link href="/notifications">{t('notifications')}</Link>
                      </li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
              )}
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton className="flex justify-between items-center">
                    <div>{t('language')}</div>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <ChevronRightIcon
                          className={`w-6 transition-transform transform ${
                            expanded ? 'rotate-90' : 'rotate-0'
                          }`}
                        />
                      )}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="font-normal pl-10 pt-6">
                  <ul className="space-y-4">
                    <li onClick={() => handleLanguageClick(Language.ES)}>
                      Español
                    </li>
                    <li onClick={() => handleLanguageClick(Language.EN)}>
                      English
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </li>
          {session && (
            <li className="py-5">
              <a onClick={() => signOut()}>{t('logout')}</a>
            </li>
          )}
        </ul>
        {!session && (
          <div className="flex flex-col p-4 space-y-3">
            <Link
              className="bg-accent w-full py-3 rounded-lg text-center"
              href="/login"
            >
              {t('login')}
            </Link>
            <Link
              className="bg-white bg-opacity-10 py-3 rounded-lg text-center"
              href="/register"
            >
              {t('register')}
            </Link>
          </div>
        )}
      </div>
    </aside>
  );
};

export default MobileMenu;
