import React, { useEffect } from 'react';
import { AppContextType, NavbarKey } from '@types';
import { useRouter } from 'next/router';
import * as gtag from '../utils/gtag';

type Props = {
  children: React.ReactChild;
};

export const AppContext = React.createContext<AppContextType>(null);

export const AppProvider: React.FC<Props> = ({ children }: Props) => {
  const router = useRouter();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isWishlistModalOpen, setIsWishlistModalOpen] = React.useState(false);
  const [currentNavbarKey, setCurrentNavbarKey] = React.useState<NavbarKey>(
    router.pathname as NavbarKey
  );
  const [searchCriteria, setSearchCriteria] = React.useState('');

  useEffect(() => {
    setCurrentNavbarKey(router.pathname as NavbarKey);
    setSearchCriteria('');
    setIsMenuOpen(false);
    gtag.pageview(router.asPath);
  }, [router.asPath]);

  const onCancelWishlistModal = () => setIsWishlistModalOpen(false);

  const onAcceptWishlistModal = () => {
    setIsWishlistModalOpen(false);
    router.push('/login');
  };

  return (
    <AppContext.Provider
      value={{
        currentNavbarKey,
        isMenuOpen,
        isWishlistModalOpen,
        setIsWishlistModalOpen,
        onCancelWishlistModal,
        onAcceptWishlistModal,
        searchCriteria,
        setSearchCriteria,
        setCurrentNavbarKey,
        setIsMenuOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
