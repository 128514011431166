/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import Downshift from 'downshift';
import Link from 'next/link';

type Props = {
  icon: React.ReactElement;
  items: {
    id: number;
    slug: string;
    value: string;
    callback?: () => void;
  }[];
};

const ProfileMenu: React.FC<Props> = ({ icon, items }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleStateChange = React.useCallback((state) => {
    if (state.isOpen !== undefined) {
      setIsOpen(false);
    }
  }, []);

  const handleSelectClick = React.useCallback(
    () => setIsOpen(!isOpen),
    [isOpen]
  );

  const handleOptionClick = (item) => {
    const { callback } = item;
    setIsOpen(!isOpen);
    if (callback) {
      callback();
    }
  };

  return (
    <Downshift
      isOpen={isOpen}
      itemToString={(item) => (item ? item.value : '')}
      onStateChange={handleStateChange}
    >
      {({ isOpen }) => (
        <div className="relative z-50">
          <div
            className="flex justify-between items-center h-full bg-transparent text-sm cursor-pointer focus:ring-0 overflow-ellipsis space-x-2"
            onClick={handleSelectClick}
          >
            <div>{icon}</div>
          </div>

          {isOpen ? (
            <ul className="absolute right-0 top-0 mt-12 bg-black bg-opacity-70 rounded-md py-2 space-y-1 w-44">
              {items.map((item) => (
                <li
                  className="flex"
                  key={item.id}
                  onClick={() => handleOptionClick(item)}
                >
                  <Link
                    className="w-full cursor-pointer hover:bg-gray-700 px-4 py-2"
                    href={`${item.slug}`}
                  >
                    {item.value}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

export default ProfileMenu;
